import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BackIntro from "../../Components/Cards/BackIntro";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";
import CourseApi from "../../api/services/CourseApi";
import { useSelector } from "react-redux";
import Urls from "../../api/Urls";

const Lessons = () => {
  const { courseId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isLogedIn = useSelector((state) => state.appReducer.isLogedIn);
  const [isLoading, setIsLoading] = useState(false);
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    fetchApiData();

    scrollToTop();
  }, []);

  const fetchApiData = () => {
    setIsLoading(true);

    CourseApi.getLessons(courseId, {
      onSuccess: (response) => {
        // console.log("getLessons", response);
        setIsLoading(false);
        setLessons(response.lessons);
      },
      onError: () => setIsLoading(false),
    });
  };

  const onRequestPressed = () => {
    // Alert.alert(
    //   t("courseEducation.request_course").toString(),
    //   t("courseEducation.request_course_full").replace(
    //     "_PRICE_",
    //     state?.courseData?.price
    //   ),
    //   [
    //     { text: "No", style: "cancel", onPress: () => {} },
    //     {
    //       text: "Yes",
    //       style: "destructive",
    //       onPress: () => {
    //         props.navigation.navigate("RequestConfirmation", {
    //           serviceId: state?.courseData?.id,
    //           type: "COURSE",
    //           price: state?.courseData?.price,
    //           mainRequirements: [],
    //         });
    //       },
    //     },
    //   ]
    // );
  };

  const generateLink = (item, index) => {
    if (state?.comeFrom === "ARGUMENTO") {
      return `/lessons/${item.id}/sections`;
    } else {
      if (index === 0) {
        return {
          pathname: `/lessons/${item.id}`,
          state: { data: item },
        };
      } else {
        if (!isLogedIn) {
          return "/login";
        } else if (!state?.courseData.user_has_course) {
          onRequestPressed();
          return "";
        } else {
          return {
            pathname: `/lessons/${item.id}`,
            state: { data: item },
          };
        }
      }
    }
  };

  // console.log("lessons", lessons);
  if (isLoading) {
    return (
      <div className="centerLoading">
        {" "}
        <div className="loading_circle"></div>
      </div>
    );
  } else {
    return (
      <div className="course_contaner">
        <BackIntro title="Courses" />
        <div className="couser_main">
          <h2 className="c_title">{state?.courseData?.name}</h2>
          <p className="c_text">
            There will be cost for this service{" "}
            <span>{`Fee ${state?.courseData?.price} Euro`}</span>
          </p>

          <div className="course_demo_info">
            <ul className="course_days">
              {lessons?.map((item, index) => (
                <li key={item.id}>
                  {index === 0 || state?.courseData.user_has_course ? (
                    <Link
                      to={generateLink(item, index)}
                      className="courseDemoItem"
                    // onClick={() => {
                    //   if (state?.comeFrom == "ARGUMENTO") {
                    //     navigate(`/lessons/${item.id}/sections`);
                    //   } else {
                    //     if (index == 0) {
                    //       navigate(`/lessons/${item.id}`, {
                    //         state: { data: item },
                    //       });
                    //     } else {
                    //       if (!isLogedIn) {
                    //         navigate("/login");
                    //       } else if (!state?.courseData.user_has_course) {
                    //         onRequestPressed();
                    //       } else {
                    //         navigate(`/lessons/${item.id}`, {
                    //           state: { data: item },
                    //         });
                    //       }
                    //     }
                    //   }
                    // }}
                    >
                      <span>
                        {item.class_name}
                      </span>
                      {
                        item.lesson_image &&
                        <div className="image">
                          <img src={Urls.storagePath + item.lesson_image} alt="" />
                        </div>
                      }
                    </Link>
                  ) : (
                    <span>{item.class_name}</span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
};

export default Lessons;
