import React, { useContext, useEffect, useState } from "react";
import useTitle from "../Hooks/useTitle";
import BonusServices from "../Components/Services/BonusServices";
import OtherServices from "../Components/Services/OtherServices";
import AllCafService from "../Components/Services/AllCafService";
import { AuthContext } from "../Context/AuthProvider";
import TakenServices from "../Components/Services/TakenServices";
import CafApi from "../api/services/CafApi";
import scrollToTop from "../Components/ScrollTop/ScrollTop";

const CafService = () => {
  useTitle("Caf Service");

  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const [userServiceList, setUserServiceList] = useState([]);

  useEffect(() => {
    if (user === null) {
      CafApi.getCafs({
        onSuccess: (response) => {
          setIsLoading(false);
          setServiceList(response?.services);
        },
        onError: () => setIsLoading(false),
      });
    } else {
      CafApi.getUserCafs({
        onSuccess: (response) => {
          setIsLoading(false);
          setServiceList(response?.services);
          setUserServiceList(
            response?.user_services ? response.user_services : []
          );
        },
        onError: () => setIsLoading(false),
      });
    }
  }, [user]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  // console.log("cafService", cafService);
  const cafServices = serviceList.filter((fitem) => fitem.type == "CAF");
  const cafBonusServices = serviceList.filter(
    (fitem) => fitem.type == "CAF-BONUS"
  );
  const cafOtherServices = serviceList.filter(
    (fitem) => fitem.type == "CAF-OTHERS"
  );

  if (isLoading) {
    return (
      <div className="centerLoading">
        {" "}
        <div className="loading_circle"></div>
      </div>
    );
  } else {
    return (
      <div className="container">
        <TakenServices type="caf" takenList={userServiceList} showNumber={3} />
        <AllCafService services={cafServices} showNumber={10} />
        <BonusServices services={cafBonusServices} showNumber={10} />
        <OtherServices services={cafOtherServices} showNumber={10} />
      </div>
    );
  }
};

export default CafService;
