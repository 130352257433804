import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from './types';

const initialState: AppState = {
  isFetchingApiData: false,
  isAlreadyOpenApp: false,
  isLogedIn: false,
  unreadNotifCount: 0,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload
    showSpinner: state => {
      state.isFetchingApiData = true;
    },
    hideSpinner: state => {
      state.isFetchingApiData = false;
    },
    setIsAlreadyOpenApp: (state, action: PayloadAction<boolean>) => {
      state.isAlreadyOpenApp = action.payload;
    },
    setIsLogedIn: (state, action: PayloadAction<boolean>) => {
      state.isLogedIn = action.payload;
    },
    incrementUnreadyNotif: state => {
      state.unreadNotifCount = state.unreadNotifCount + 1;
    },
    resetUnreadyNotif: state => {
      state.unreadNotifCount = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showSpinner,
  hideSpinner,
  setIsAlreadyOpenApp,
  setIsLogedIn,
  incrementUnreadyNotif,
  resetUnreadyNotif,
} = appSlice.actions;

export default appSlice.reducer;
