import React, { useContext, useEffect, useState } from "react";
import Urls from "../api/Urls";
import { Link } from "react-router-dom";
import TourApi from "../api/services/TourApi";
import { AuthContext } from "../Context/AuthProvider";
import TakenServices from "../Components/Services/TakenServices";
import scrollToTop from "../Components/ScrollTop/ScrollTop";

const Tour = () => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [tourServices, setTourServices] = useState([]);
  const [userTourList, setUserTourList] = useState([]);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const fetchDashboardData = () => {
    setIsLoading(true);

    if (user === null) {
      TourApi.getTours({
        onSuccess: (response) => {
          setIsLoading(false);
          setTourServices(response.tours);
        },
        onError: () => setIsLoading(false),
      });
    } else {
      TourApi.getUserTours({
        onSuccess: (response) => {
          setIsLoading(false);
          setTourServices(response.tours);
          setUserTourList(response?.user_tours ? response.user_tours : []);
        },
        onError: () => setIsLoading(false),
      });
    }
  };

  if (isLoading) {
    return (
      <div className="centerLoading">
        <div className="loading_circle"></div>
      </div>
    );
  } else
    return (
      <div className="container">
        {/* <h3>{"Already taken services"}</h3> */}
        <TakenServices type="tour" takenList={userTourList} showNumber={3} />

        <h3>{"Please select a service"}</h3>
        {tourServices.map((item) => (
          <Link
            to={`/service-requerment/tour/${item.id}`}
            className="tour_item"
            key={item.id}
          >
            <img src={Urls.storagePath + item.thumbnail_pic} alt="" />
            <div>
              <div
                className="tour_Title"
                dangerouslySetInnerHTML={{ __html: item?.name }}
              />
              <div
                className="tour_des"
                dangerouslySetInnerHTML={{ __html: item?.short_description }}
              />
            </div>
          </Link>
        ))}
      </div>
    );
};

export default Tour;
