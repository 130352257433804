import React from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthContext } from "../../Context/AuthProvider";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";

const Add = () => {
  const { user } = React.useContext(AuthContext);

  const location = useLocation();
  const currentPath = location.pathname;

  const navigate = useNavigate();

  React.useEffect(() => {
    if (currentPath === "/ads" || currentPath === "/ads/") {
      navigate("/ads/all-ads");
    }
  }, [currentPath, navigate]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="container">
      <h2>Advertisement</h2>
      <Link to="/add-advertisement" className="btn exc_btn">
        +Add New
      </Link>
      <div className="user_switch">
        <NavLink
          to={`/ads/all-ads`}
          className={({ isActive }) => (isActive ? "add_user" : "")}
        >
          All Ads
        </NavLink>
        {user && (
          <NavLink
            to={`/ads/user-ads`}
            className={({ isActive }) => (isActive ? "add_user" : "")}
          >
            Your Ads
          </NavLink>
        )}
      </div>
      <div className="advertis_container">
        <Outlet />
      </div>
    </div>
  );
};

export default Add;
