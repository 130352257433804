import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import theory from "../Assets/theory.svg";
import quiz from "../Assets/quiz.svg";
import BackIntro from "../Components/Cards/BackIntro";
import useTitle from "../Hooks/useTitle";
import { AuthContext } from "../Context/AuthProvider";
import scrollToTop from "../Components/ScrollTop/ScrollTop";
import { RootState } from "../store/Store";
import { useSelector } from "react-redux";

const PatanteeType = () => {
  useTitle("Patentee");
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const patenteLessons = useSelector(
    (state: RootState) => state.userReducer.patenteLessons
  );

  React.useEffect(() => {
    scrollToTop();
  }, []);

  // console.log("patenteLessons", patenteLessons);
  return (
    <div className="NcPat_container">
      <BackIntro title={"Education"} />
      <div className="NcPat_main">
        <h2 className="c_title">NC PATENTEE</h2>
        <div className="NcPat_sub">
          <div className="NcPat_box">
            <Link
              to={`/${patenteLessons?.id}/lessons`}
              className="ncPat_Link green"
            >
              <div className="nc_item">
                <span className="nc_text">
                  TEORIA - Tuttle le lezioni
                  <br />
                  সকল চ্যাপ্টার এর ক্লাস
                </span>
                <img className="nc_img" src={theory} alt="" />
              </div>
            </Link>

            <div
              className="ncPat_Link pink"
              onClick={() => navigate("/patente/quiz-type")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Quiz
                  <br />
                  কুইজ
                </span>
                <img className="nc_img" src={quiz} alt="" />
              </div>
            </div>

            <div
              className="ncPat_Link green"
              // onClick={() => userRedirect("/dictionary")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Dictionary
                  <br />
                  অভিধান
                </span>
                <img className="nc_img" src={quiz} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatanteeType;
