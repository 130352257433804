import React, { useContext, useEffect, useState } from "react";
import useTitle from "../../Hooks/useTitle";
import { RequestInsuranceServiceApiService } from "../../api/ApiServices";
import { toast_error, toast_success } from "../../Toast/Toast";
import moment from "moment";
import TakenService from "./TakenServices";
import { AuthContext } from "../../Context/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import InsuranceApi from "../../api/services/InsuranceApi";
import scrollToTop from "../ScrollTop/ScrollTop";

const InsuranceService = () => {
  useTitle("Insurance Service");
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [reqLoading, setReqIsLoading] = useState(false);
  const [userInsuranceList, setUserInsuranceList] = useState([]);
  const [departure_date, setDeparture_date] = useState();
  const [departure_city, setDeparture_city] = useState();
  const [departure_address, setDeparture_address] = useState();
  const [arraival_city, setArraival_city] = useState();
  const [arraival_address, setArraival_address] = useState();
  const [adults, setAdults] = useState();
  const [childs, setChilds] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    InsuranceApi.getUserInsurances({
      showIndicator: true,
      onSuccess: (response) => setUserInsuranceList(response.insurances),
    });
    scrollToTop();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!user) {
      toast_error("Please login first");
      return navigate("/login", { state: { from: location }, replace: true });
    }

    setReqIsLoading(true);

    if (!departure_date) {
      toast_error("Please fill in the Date of Birth field");
      setReqIsLoading(false);
      return;
    }
    if (!departure_city) {
      toast_error("Please fill in the Departure City field");
      setReqIsLoading(false);
      return;
    }
    if (!arraival_city) {
      toast_error("Please fill in the Arrival City field");
      setReqIsLoading(false);
      return;
    }
    if (!adults) {
      toast_error("Please fill in the Adults field");
      setReqIsLoading(false);
      return;
    }

    const currentTime = new Date().toLocaleTimeString("en-US", {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    const departureDateTime = `${departure_date} ${currentTime}`;

    const params = {
      departure_date: moment(departureDateTime).format("YYYY-MM-DD HH:mm:ss"),
      departure_city: departure_city,
      address: departure_address,
      arrival_city: arraival_city,
      arrival_address: arraival_address,
      adults: adults,
      child: childs,
    };

    console.log("params", params);

    RequestInsuranceServiceApiService(
      params,
      (message) => {
        setReqIsLoading(false);
        navigate("/service-success", { state: { message: message } });
      },
      () => setReqIsLoading(false)
    );
  };

  return (
    <div className="container">
      {isLoading ? (
        <div className="centerLoading">
          {" "}
          <div className="loading_circle"></div>
        </div>
      ) : (
        <TakenService type={"insurance"} takenList={userInsuranceList} />
      )}

      <div className="ticket_container">
        <div className="ticket_main">
          <h2 className="dlt">Book a Car For Trip</h2>
          <form onSubmit={handleSubmit} className="ticket_form" action="">
            <div className="input_div">
              <label htmlFor="Date_of_birth">Departure Date</label>
              <input
                type="date"
                placeholder="Enter your last name"
                onChange={(e) => setDeparture_date(e.target.value)}
              />
            </div>
            <div className="ticket_input">
              <div className="input_div">
                <label htmlFor="departure_city">Departure City</label>
                <br />
                <input
                  type="text"
                  placeholder="Departure City"
                  onChange={(e) => setDeparture_city(e.target.value)}
                />
              </div>
              <div className="input_div">
                <label htmlFor="departure_address">Departure Address</label>
                <br />
                <input
                  type="text"
                  placeholder="Departure Address"
                  onChange={(e) => setDeparture_address(e.target.value)}
                />
              </div>
            </div>
            <div className="ticket_input">
              <div className="input_div">
                <label htmlFor="arraival_city">Arrival City</label>
                <br />
                <input
                  type="text"
                  placeholder="Arrival City"
                  onChange={(e) => setArraival_city(e.target.value)}
                />
              </div>
              <div className="input_div">
                <label htmlFor="arraival_address">Arraival Address</label>
                <br />
                <input
                  type="text"
                  placeholder="Arrival Address"
                  onChange={(e) => setArraival_address(e.target.value)}
                />
              </div>
            </div>
            <div className="ticket_input">
              <div className="input_div">
                <label htmlFor="country">Adults</label>
                <input
                  type="number"
                  id="country"
                  placeholder="Adults"
                  onChange={(e) => setAdults(e.target.value)}
                />
              </div>
              <div className="input_div">
                <label htmlFor="city">Child</label>
                <input
                  type="number"
                  id="city"
                  placeholder="Childs"
                  onChange={(e) => setChilds(e.target.value)}
                />
              </div>
            </div>
            <div className="ticket_submit">
              <button
                disabled={reqLoading ? true : false}
                className={`btn ${reqLoading ? "disabaled" : ""}`}
                onClick={handleSubmit}
              >
                {reqLoading ? (
                  <div className="loading_circle_btn"></div>
                ) : (
                  "Submit Request"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InsuranceService;
