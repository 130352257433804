import { RouterProvider } from "react-router-dom";
import "./App.css";
import "./app-responsive.css";
import { Router } from "./Router/Route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext, useEffect } from "react";
import { AuthContext } from "./Context/AuthProvider";
import { GetProfileApiService } from "./api/ApiServices";
import OneSignal from "react-onesignal";
import 'react-loading-skeleton/dist/skeleton.css'

async function runOneSignal() {
  await OneSignal.init({
    appId: "7d4acf69-93ea-4957-973e-f748aa395521",
    // allowLocalhostAsSecureOrigin: true,
  });
  OneSignal.Slidedown.promptPush();
}

function App() {
  const { setUser, saveUserProfile } = useContext(AuthContext);

  useEffect(() => {
    // OneSignal.init({ appId: "7d4acf69-93ea-4957-973e-f748aa395521" });
    runOneSignal();

    // console.log("useEffect called");
    const accessToken = localStorage.getItem("access_token");
    const user = localStorage.getItem("user");
    if (accessToken) {
      //   console.log("token have", accessToken);
      window.accessToken = accessToken;
    }

    if (user) {
      //   console.log("user have");
      const parseUser = JSON.parse(user);
      // console.log("parseUser", parseUser);
      setUser(parseUser);
      GetProfileApiService((response: any) => saveUserProfile(response));
    }
  }, []);

  return (
    <div className="app">
      <RouterProvider router={Router}></RouterProvider>

      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default App;
