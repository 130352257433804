import React, { useEffect, useState } from "react";
import SecCard from "../Cards/SecCard";
import AppSuggestion from "../AppSugestoion/AppSuggestion";
import TakenService from "../Services/TakenServices";
import useTitle from "../../Hooks/useTitle";
import scrollToTop from "../ScrollTop/ScrollTop";
import { RootState } from "../../store/Store";
import { useDispatch, useSelector } from "react-redux";
import CourseApi from "../../api/services/CourseApi";
import {
  setA2b1Lessons,
  setCourseList,
  setPatenteLessons,
} from "../../store/user/userSlice";
import { useNavigate } from "react-router-dom";

const Education = () => {
  useTitle("Education");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogedIn = useSelector(
    (state: RootState) => state.appReducer.isLogedIn
  );
  const [userCourseList, setUserCourseList] = useState<any[]>([]);
  const courseList = useSelector(
    (state: RootState) => state.userReducer.courseList
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLogedIn) {
      CourseApi.getUserCourses({
        onSuccess: (response) => setUserCourseList(response.user_courses),
      });
    }

    CourseApi.getCources({
      showIndicator: true,
      onSuccess: (response) => {
        console.log("response", response.course);
        dispatch(setCourseList(response.course));
      },
    });

    scrollToTop();
  }, []);

  const onCourseItemPress = (item: any) => {
    if (item.course_type === "patente") {
      dispatch(setPatenteLessons(item));
      navigate("/patente");
    } else if (item.course_type === "a1b2") {
      dispatch(setA2b1Lessons(item));
      navigate("/a2b1/lessons");
    } else if (item.course_type === "caf") {
      navigate("/caf/lessons");
    }
  };

  // console.log("userCourseList", userCourseList);
  return (
    <div className="container">
      {isLoading ? (
        <div className="centerLoading">
          <div className="loading_circle"></div>
        </div>
      ) : (
        userCourseList.length > 0 && (
          <TakenService type="course" takenList={userCourseList} />
        )
      )}
      <div className="services visa">
        {courseList.map((item, index) => (
          <SecCard
            key={index}
            index={index}
            title={item.display_name}
            title_b={item.display_name_bangla}
            onClick={() => onCourseItemPress(item)}
          />
        ))}

      </div>
      <AppSuggestion />
    </div>
  );
};

export default Education;
