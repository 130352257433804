import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import theory from "../Assets/theory.svg";
import quiz from "../Assets/quiz.svg";
import BackIntro from "../Components/Cards/BackIntro";
import useTitle from "../Hooks/useTitle";
import { AuthContext } from "../Context/AuthProvider";
import scrollToTop from "../Components/ScrollTop/ScrollTop";
import { useSelector } from "react-redux";
import { RootState } from "../store/Store";

const QuizType = () => {
  useTitle("Quiz Type");
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const patenteLessons = useSelector(
    (state: RootState) => state.userReducer.patenteLessons
  );

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const userRedirect = (url: string) => {
    if (user == null) {
      return navigate("login");
    } else if (patenteLessons?.user_has_course) {
      switch (url) {
        case "simulation":
          return navigate("/patente/quiz/simulation", {
            state: { type: "simulation" },
          });
        case "capitoli-selection":
          return navigate(`/patente/${patenteLessons?.id}/capitoli-selection`);
        case "argumento":
          return navigate(`/${patenteLessons?.id}/lessons`, {
            state: { courseData: patenteLessons, comeFrom: "ARGUMENTO" },
          });
        default:
          console.log("Unknown URL:", url);
      }
    } else {
      console.log("User has no course. Please request.");
    }
  };

  // console.log("patenteLessons", patenteLessons);
  return (
    <div className="NcPat_container">
      <BackIntro title={"Education"} />
      <div className="NcPat_main">
        <h2 className="c_title">Quiz Type</h2>
        <div className="NcPat_sub">
          <div className="NcPat_box">
            <div
              className="ncPat_Link green"
              onClick={() => userRedirect("simulation")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Simulazione di Esame
                  <br />
                  পরিক্ষা অনুরূপ কুইজ
                </span>
                <img className="nc_img" src={quiz} alt="" />
              </div>
            </div>

            <div
              className="ncPat_Link pink"
              onClick={() => userRedirect("capitoli-selection")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Quiz per Capitoli
                  <br />
                  চ্যাপ্টার অনুসারে কুইজ
                </span>
                <img className="nc_img" src={quiz} alt="" />
              </div>
            </div>

            <div
              className="ncPat_Link green"
              onClick={() => userRedirect("argumento")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Quiz per Argumento
                  <br />
                  বিষয়বস্তু অনুসারে কুইজ
                </span>
                <img className="nc_img" src={quiz} alt="" />
              </div>
            </div>

            <div
              className="ncPat_Link pink"
              onClick={() => userRedirect("quiz-per-parole")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Quiz per Parole
                  <br />
                  একই শব্দের সকল কুইজ
                </span>
                <img className="nc_img" src={quiz} alt="" />
              </div>
            </div>

            <div
              className="ncPat_Link green"
              onClick={() => userRedirect("scheda-esame")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Scheda D'Esame
                  <br />
                  বাস্তব পরীক্ষার প্রশ্ন
                </span>
                <img className="nc_img" src={quiz} alt="" />
              </div>
            </div>

            <div
              className="ncPat_Link pink"
              onClick={() => userRedirect("statistiche")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Statistiche
                  <br />
                  পরিসংখ্যান
                </span>
                <img className="nc_img" src={quiz} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizType;
