import React, { useEffect, useState } from "react";
import BackIntro from "../Components/Cards/BackIntro";
import useTitle from "../Hooks/useTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast_error } from "../Toast/Toast";
import scrollToTop from "../Components/ScrollTop/ScrollTop";
import CourseApi from "../api/services/CourseApi";
import Urls from "../api/Urls";

const CapitoliSelection = () => {
  useTitle("Capitoli Selection");
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [leasons, setLeasons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    scrollToTop();
    fetchApiData();
  }, []);

  const fetchApiData = () => {
    setIsLoading(true);

    CourseApi.getLessons(courseId, {
      onSuccess: (response) => {
        setIsLoading(false);
        let filterQuizList = [];
        response.lessons.map((item, index) => {
          if (index > 0) {
            let quiz = {
              id: item.id,
              class_name: item.class_name,
              lesson_image: item.lesson_image,
              isSelected: false,
            };
            filterQuizList.push(quiz);
          }
        });
        setLeasons(filterQuizList);
      },
      onError: () => setIsLoading(false),
    });
  };

  const onCheckBoxClicked = (index) => {
    let newArray = [...leasons];
    newArray[index].isSelected = !newArray[index].isSelected;
    setLeasons(newArray);
  };

  const onContinuePressed = () => {
    const selectedIds = leasons.reduce((result, item) => {
      if (item.isSelected) {
        return [...result, item.id];
      }
      return result;
    }, []);

    if (selectedIds.length === 0) {
      return toast_error("Please select at least one capitoli.");
    }

    // console.log('selectedIds', selectedIds);
    navigate("/patente/quiz/capitoli", { state: { capitoliIds: selectedIds } });
  };

  // console.log("leasons", leasons);
  return (
    <div className="quiz_container">
      <BackIntro title="Quiz" />

      <div className="quiz_main">
        <h2 className="c_title">Capitoli selection</h2>
        <p className="c_text">Please select chapter first</p>

        <div className="course_demo_info">
          {isLoading ? (
            <div className="centerLoading">
              {" "}
              <div className="loading_circle"></div>
            </div>
          ) : (
            <ul className="course_days">
              {leasons.map((item, index) => (
                <li key={item.id}>
                  <input
                    type="checkbox"
                    checked={item.isSelected}
                    onClick={() => onCheckBoxClicked(index)}
                  />
                  <Link
                    className="courseDemoItem"
                    key={item.id}
                    // imageUrl={Urls.storagePath +item.lesson_image}
                    onClick={() => onCheckBoxClicked(index)}
                  >
                    <span>
                      {item.class_name}
                    </span>
                    {
                      item.lesson_image &&
                      <div className="image">
                        <img src={Urls.storagePath + item.lesson_image} alt="" />
                      </div>
                    }
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="button_div req_submit">
        <button className="btn" onClick={onContinuePressed}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default CapitoliSelection;
