import countries from "react-select-country-list";
const countryList = countries().getData();

const myModule = {
  getFullName: (firstName, lastName) => {
    let fullName = "";
    if (firstName) {
      fullName = firstName;
    }
    if (lastName) {
      fullName = fullName + " " + lastName;
    }
    return fullName;
  },
  translateApi: (text) => {
    const response = fetch(
      `https://api.mymemory.translated.net/get?q=${encodeURIComponent(
        text
      )}&langpair=it|bn`
    );
    return response;
  },
  getCountryNameByCountryCode: (countryCode) => {
    const country = countryList.find((item) => item.value === countryCode);
    if (country) {
      return country.label;
    }
    return "";
  },
};

export default myModule;