import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const CommonSkeleton = () => {
    return (
        <div className="importentNotesMain">


            {[...Array(6)].map((_, index) => (
                <div className="notesItem" key={index}>
                    <div className="notesImg">
                        <div className="notesDetails">
                            <Skeleton count={2} />
                        </div>
                        <Skeleton className="Image" square={true} height={80} width={80} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CommonSkeleton;