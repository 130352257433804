import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main/Main";
import Home from "../pages/Home";
import SignupSuccess from "../Components/SignUp/SignupSuccess";
import CafService from "../pages/CafService";
import ServiceRequerment from "../Components/Services/ServiceRequerment";
import ServiceSuccess from "../Components/Services/ServiceSuccess";
import AllreadyTakenServices from "../Components/Services/AllreadyTakenServices";
import Lessons from "../pages/lessons/Lessons";
import Sections from "../pages/Sections";
import Quizzes from "../pages/Quizzes";
import LessonDetails from "../pages/lessons/LessonDetails";
import PatanteeType from "../pages/PatanteeType";
import QuizType from "../pages//QuizType";
import CapitoliSelection from "../pages/CapitoliSelection";
import QuizExam from "../Components/QuizExam/QuizExam";
import AddNewAdvertisement from "../Components/Advertisement/AddNewAdvertisement";
import Blog from "../pages/Blog";
import BlogDetils from "../pages/BlogDetails";
import VisaAndOthers from "../Components/Visa/VisaAndOthers";
import Visa from "../Components/Visa/Visa";
import Education from "../Components/Education/Education";
import Signup from "../Components/SignUp/Signup";
import PrivateRoute from "./Private";
import { Login } from "../Components/Login/Login";
import MainProfile from "../Layout/Profile/MainProfile";
import ProfileInfo from "../Components/Profile/ProfileInfo";
import Embassy from "../pages/Embassy";
import Add from "../Layout/AddLayout/Add";
import AllAdds from "../Components/Advertisement/AllAdds";
import UserAdd from "../Components/Advertisement/UserAdd";
import InsuranceService from "../Components/Services/InsuranceService";
import CarService from "../Components/Services/CarService";
import MoneyTransferRate from "../Components/MoneyTransferRate/MoneyTransferRate";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ContactUs from "../pages/ContactUs";
import TakenServiceDetails from "../Components/Services/TakenServiceDetails";
import ContactInfo from "../Components/ContactInfo/ContactInfo";
import Ticket from "../pages/Ticket";
import Tour from "../pages/Tour";
import Benefit from "../pages/Benefit";
import HelpDesk from "../Components/HelpDesk/HelpDesk";
import IssueDetails from "../pages/IssueDetails";

export const Router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/caf-service",
        element: <CafService />,
      },
      {
        path: "/service-requerment/:type/:id",
        element: <ServiceRequerment />,
      },
      {
        path: "service-details/:type/:id",
        element: (
          <PrivateRoute>
            <TakenServiceDetails />
          </PrivateRoute>
        ),
      },
      {
        path: "/user",
        element: (
          <PrivateRoute>
            <MainProfile />
          </PrivateRoute>
        ),
        children: [
          {
            path: "/user/profile",
            element: <ProfileInfo />,
          },
          {
            path: "/user/services",
            element: <AllreadyTakenServices />,
          },
          {
            path: "/user/support",
            element: <ContactInfo />,
          },
        ],
      },
      {
        path: "/embassy",
        element: <Embassy />,
      },
      {
        path: "/visa&others",
        element: <VisaAndOthers />,
      },
      {
        path: "/visa",
        element: <Visa />,
      },
      {
        path: "/education",
        element: <Education />,
      },
      {
        path: "/patente",
        element: <PatanteeType />,
      },
      {
        path: "/patente/quiz-type",
        element: <QuizType />,
      },
      {
        path: "/:courseId/lessons",
        element: (
          <PrivateRoute>
            <Lessons />
          </PrivateRoute>
        ),
      },
      {
        path: "/lessons/:lessonId/sections",
        element: (
          <PrivateRoute>
            <Sections />
          </PrivateRoute>
        ),
      },
      {
        path: "/section/:sectionId/quizzes",
        element: (
          <PrivateRoute>
            <Quizzes />
          </PrivateRoute>
        ),
      },
      {
        path: "/lesson/:id",
        element: (
          <PrivateRoute>
            <LessonDetails />
          </PrivateRoute>
        ),
      },
      {
        path: "/patente/:courseId/capitoli-selection",
        element: (
          <PrivateRoute>
            <CapitoliSelection />
          </PrivateRoute>
        ),
      },
      {
        path: "/patente/quiz/:type",
        element: (
          <PrivateRoute>
            <QuizExam />
          </PrivateRoute>
        ),
      },
      {
        path: "/ads",
        element: <Add />,
        children: [
          {
            path: "/ads/all-ads",
            element: <AllAdds />,
          },
          {
            path: "/ads/user-ads",
            element: (
              <PrivateRoute>
                <UserAdd />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: "/add-advertisement",
        element: (
          <PrivateRoute>
            <AddNewAdvertisement />
          </PrivateRoute>
        ),
      },
      {
        path: "/ticket",
        element: (
          <PrivateRoute>
            <Ticket />
          </PrivateRoute>
        ),
      },
      {
        path: "/tour",
        element: (
          <PrivateRoute>
            <Tour />
          </PrivateRoute>
        ),
      },
      {
        path: "/benefit",
        element: <Benefit />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blog-details/:id",
        element: <BlogDetils />,
      },
      {
        path: "/insurance",
        element: <InsuranceService />,
      },
      {
        path: "/car-service",
        element: <CarService />,
      },
      {
        path: "/money-transfer-rate",
        element: <MoneyTransferRate />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "/issue/:id",
        element: (
          <PrivateRoute>
            <IssueDetails />
          </PrivateRoute>
        ),
      },
      {
        path: "/helpdesk",
        element: (
          <PrivateRoute>
            <HelpDesk />
          </PrivateRoute>
        ),
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/success-signup",
        element: <SignupSuccess />,
      },
      {
        path: "/service-success",
        element: <ServiceSuccess />,
      },
    ],
  },
  {
    path: "*",
    element: <h1>404</h1>,
  },
]);
