import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.png";
import useTitle from "../../Hooks/useTitle";
import { LoginApiService } from "../../api/ApiServices";
import { AuthContext } from "../../Context/AuthProvider";
import PhoneInput from "react-phone-input-2";
import { toast_error, toast_success } from "../../Toast/Toast";
import scrollToTop from "../ScrollTop/ScrollTop";

export const Login = () => {
  useTitle("Sign In");

  const { isLoading, setIsLoading, user, saveUserProfile, saveAccessToken } =
    React.useContext(AuthContext);

  const [phone, setPhone] = React.useState("39");
  const [password, setPassword] = React.useState("");

  const nevigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";

  React.useEffect(() => {
    if (user) {
      nevigate(from);
    }
  }, [user, nevigate, from]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const successCallback = (dataParse) => {
    setIsLoading(false);
    saveUserProfile(dataParse.data);
    saveAccessToken(dataParse.access_token);
    toast_success(dataParse.message);
    nevigate(from);
  };

  const failedCallback = (dataParse) => {
    setIsLoading(false);
    toast_error(dataParse?.message);
  };

  const handlelogin = (e) => {
    e.preventDefault();
    const data = {
      phone_number: phone,
      password: password,
    };
    setIsLoading(true);
    LoginApiService(data, successCallback, failedCallback);
  };

  return (
    <div className="container">
      <div className="login_main">
        <div className="login_container">
          <div className="center_logo">
            <img className="logo" src={logo} alt="" />
            <h3>Sign in</h3>
          </div>
          <form action="" onSubmit={handlelogin}>
            <div className="login_input">
              <div className="input_div">
                <label htmlFor="">Phone Number</label>
                <br />
                <PhoneInput
                  className="phone_input"
                  name="phone_number"
                  country={"it"}
                  placeholder="Enter phone number"
                  value={phone}
                  required
                  onChange={(value) => {
                    setPhone(value);
                  }}
                />
              </div>
              <div className="input_div">
                <label htmlFor="">Password</label>
                <br />
                <input
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="login_submit">
              <span href="">
                <Link to="/forgotpassword">Forgot password?</Link>
              </span>
              <p>
                By logging, you agree to our <Link>Terms & Conditions</Link> and{" "}
                <Link>PrivacyPolicy</Link>.
              </p>
              <button disabled={isLoading ? true : false} className="btn">
                {isLoading ? (
                  <div className="loading_circle_btn"></div>
                ) : (
                  "Sign in"
                )}
              </button>
              <Link className="btn btn_def" to="/signup">
                Sign up as member
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
