import React, { useContext, useEffect } from "react";
import sign_out_icon from "../../Assets/sign_out.svg";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthProvider";
import ProfileSec from "../../Components/Profile/ProfileSec";
import { GetProfileApiService } from "../../api/ApiServices";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";

const MainProfile = () => {
  const { user, saveUserProfile, signOut } = useContext(AuthContext);
  const match = useMatch("/user");
  const navigate = useNavigate();

  React.useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (user != null) {
      GetProfileApiService((response) => saveUserProfile(response));
    }
  }, []);

  useEffect(() => {
    if (match) {
      navigate("/user/profile");
    }
  }, [match, navigate]);

  // console.log("user", user);
  return (
    <div className="container">
      <div className="p_main_sec">
        <ProfileSec />
        <Outlet />
        <div className="sign_out btn down_btn" onClick={signOut}>
          <button>
            <img className="d_icons" src={sign_out_icon} alt="" /> Sign Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainProfile;
