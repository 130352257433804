import React, { useContext } from "react";
import logo from "../../Assets/logo.png";
import logoRes from "../../Assets/logo-res.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import down from "../../Assets/down-arrow-icon.svg";
import user_icon from "../../Assets/profile-outline.svg";
import service_icon from "../../Assets/service-outline.svg";
import support_icon from "../../Assets/support.svg";
import sign_out_icon from "../../Assets/sign_out.svg";
import avatar from "../../Assets/avatar.png";
import { FaBars, FaRegBell } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { AuthContext } from "../../Context/AuthProvider";
import Urls from "../../api/Urls";
import google from "../../Assets/googlePlay.webp";
import appStore from "../../Assets/AppStore.webp";
import moment from "moment";
import Notification from "../../Components/Notification/Notification";

const Header = () => {
  const { user, click, setClick, signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClick = () => {
    setClick(!click);
  };

  const handleFalse = () => {
    setClick(false);
  };

  const menuItems = (
    <React.Fragment>
      <li onClick={handleFalse}>
        <NavLink
          to="/"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          Home
        </NavLink>
      </li>
      <li onClick={handleFalse}>
        <NavLink
          to="/caf-service"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          Caf service
        </NavLink>
      </li>
      <li onClick={handleFalse}>
        <NavLink
          to="/visa&others"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          VISA & others
        </NavLink>
      </li>
      <li onClick={handleFalse}>
        <NavLink
          to="/education"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          Education
        </NavLink>
      </li>
      <li onClick={handleFalse}>
        <NavLink
          to="/blog"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          Blog
        </NavLink>
      </li>

      <li onClick={handleFalse}>
        <div className="hover_drop_more">
          <ul className="nav_head ">
            <li className="nav_drop_name">More</li>
            <li>
              <img className="down_icon_more" src={down} alt="" />
            </li>
          </ul>
          <div className="drom_down_more">
            <ul className="drop_box_more">
              <li onClick={handleFalse}>
                <NavLink
                  to="/embassy"
                  className={({ isActive, isPending }) => {
                    const dynamicClassName = isPending
                      ? "pending"
                      : isActive
                        ? "active"
                        : "";
                    const staticClassName = "nav_link";
                    return `${staticClassName} ${dynamicClassName}`;
                  }}
                >
                  Embassy
                </NavLink>
              </li>
              <li onClick={handleFalse}>
                <NavLink
                  to="/benefit"
                  className={({ isActive, isPending }) => {
                    const dynamicClassName = isPending
                      ? "pending"
                      : isActive
                        ? "active"
                        : "";
                    const staticClassName = "nav_link";
                    return `${staticClassName} ${dynamicClassName}`;
                  }}
                >
                  Benefits
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/helpdesk"
                  className={({ isActive, isPending }) => {
                    const dynamicClassName = isPending
                      ? "pending"
                      : isActive
                        ? "active"
                        : "";
                    const staticClassName = "nav_link";
                    return `${staticClassName} ${dynamicClassName}`;
                  }}
                >
                  HelpDesk
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </React.Fragment>
  );


  const menuItemsRes = (
    <React.Fragment>
      <li onClick={handleFalse}>
        <NavLink
          to="/"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          Home
        </NavLink>
      </li>
      <li onClick={handleFalse}>
        <NavLink
          to="/caf-service"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          Caf service
        </NavLink>
      </li>
      <li onClick={handleFalse}>
        <NavLink
          to="/visa&others"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          VISA & others
        </NavLink>
      </li>
      <li onClick={handleFalse}>
        <NavLink
          to="/education"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          Education
        </NavLink>
      </li>
      <li onClick={handleFalse}>
        <NavLink
          to="/blog"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          Blog
        </NavLink>
      </li>

      <li onClick={handleFalse}>
        <NavLink
          to="/embassy"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          Embassy
        </NavLink>
      </li>
      <li onClick={handleFalse}>
        <NavLink
          to="/benefit"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          Benefits
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/helpdesk"
          className={({ isActive, isPending }) => {
            const dynamicClassName = isPending
              ? "pending"
              : isActive
                ? "active"
                : "";
            const staticClassName = "nav_link";
            return `${staticClassName} ${dynamicClassName}`;
          }}
        >
          HelpDesk
        </NavLink>
      </li>
    </React.Fragment>
  );

  const user_info = (
    <div className="user_nav">
      <div className="user_on_nav">
        <div className="notif_main">
          <div className="notification">
            <FaRegBell />
          </div>
          {user && <Notification />}
        </div>
        {user?.yearly_membership?.status === "ACTIVE" && (
          <div className="valid_date_nav">
            <div className="date_sec">
              <p>Membership valid till</p>
              <h2>
                {moment(user?.yearly_membership?.expire_date).format(
                  "DD/MM/YYYY"
                )}
              </h2>
            </div>
          </div>
        )}
        <div className="hover_drop">
          <ul className="auth_user ">
            <li>
              {user?.profile_pic ? (
                <img
                  className="user_img"
                  src={
                    user?.profile_pic
                      ? Urls.storagePath + user?.profile_pic
                      : avatar
                  }
                  alt=""
                />
              ) : (
                <img className="user_img" src={avatar} alt="" />
              )}
            </li>
            <li className="user_name">
              <span>
                {user?.first_name} <br /> {user?.last_name}
              </span>
            </li>
            <li>
              <img className="down_icon" src={down} alt="" />
            </li>
          </ul>
          <div className="drom_down">
            <ul className="drop_box">
              <li>
                <div className="d_icons">
                  <img src={user_icon} alt="" />
                </div>
                <Link to="/user/profile">My Profile</Link>
              </li>
              <li>
                <div className="d_icons">
                  <img src={service_icon} alt="" />
                </div>
                <Link to="/user/services">Services</Link>
              </li>
              <li>
                <div className="d_icons">
                  <img src={support_icon} alt="" />
                </div>
                <Link to="/user/support">Support</Link>
              </li>
              <li>
                <div className="sign_out">
                  <span className="sign_out" onClick={signOut}>
                    <img className="d_icons" src={sign_out_icon} alt="" /> Sign
                    Out
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  const login_signup = (
    <ul className="log_auth">
      <li>
        <NavLink
          to="/login"
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active-l" : ""
          }
        >
          Sign in
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/signup"
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active-l" : ""
          }
        >
          Sign up
        </NavLink>
      </li>
    </ul>
  );

  const clickLogo = () => {
    navigate("/");
  };

  return (
    <div>
      <div className="container">
        <div className="main_header">
          <div className="header_logo" onClick={clickLogo}>
            <img className="logo" src={logo} alt="" />
          </div>
          <div className="navbar">
            <ul>{menuItems}</ul>
          </div>
          <div className="auth">{user ? user_info : login_signup}</div>
        </div>

        <div className="main_header_res">
          <div className="header_res_container">
            <div className="header_logo">
              <div className="bar_icon">
                {click ? (
                  <AiOutlineClose className="bar_icon" onClick={handleClick} />
                ) : (
                  <FaBars className="bar_icon" onClick={handleClick} />
                )}
              </div>
              <img className="logo logo_res" src={logoRes} alt="" />
            </div>
            <div className="auth">{user ? user_info : login_signup}</div>
          </div>
          <div className={`navbar ${click ? "nav_res" : ""}`}>
            <ul>{menuItemsRes}</ul>
          </div>
        </div>
      </div>

      <div className="download_app">
        <span>
          To get better experience Please download our Probashi Seba app
        </span>
        <div className="n_download">
          <Link
            to="https://play.google.com/store/apps/details?id=com.probashiseba"
            target="_blank"
          >
            <img src={google} alt="" />
          </Link>
          <Link
            to="https://apps.apple.com/us/app/probashi-seba/id6450273025"
            target="_blank"
          >
            <img src={appStore} alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
