import React from "react";

import { AuthContext } from "../Context/AuthProvider";
import PhoneInput from "react-phone-input-2";
import ContactInfo from "../Components/ContactInfo/ContactInfo";
import scrollToTop from "../Components/ScrollTop/ScrollTop";

const ContactUs = () => {
  const { user, isLoading, setIsLoading } = React.useContext(AuthContext);

  const [first_name, setFirst_name] = React.useState("");
  const [last_name, setLast_name] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("39");
  const [radio, setRadio] = React.useState("");
  const [details, setDetails] = React.useState("");

  React.useEffect(() => {
    if (user) {
      setFirst_name(user?.first_name);
      setLast_name(user?.last_name);
      setEmail(user?.email);
      setPhone(user?.phone_number);
    }
  }, [user]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
  };

  console.log(radio);

  return (
    <div className="container">
      <div className="center_logo">
        <h1 className="center_text">Contact Us</h1>
        <p>Any question or remarks? Just write us a message!</p>
      </div>
      <div className="contect_us">
        <ContactInfo />
        <form onSubmit={handleSubmit} className="modal_form">
          <div className="signup_input">
            <div className="input_div">
              <label htmlFor="first_name">First Name</label>
              <br />
              <input
                type="text"
                name="first_name"
                required
                value={first_name}
                onChange={(e) => setFirst_name(e.target.value)}
                placeholder="Enter your first name"
              />
            </div>
            <div className="input_div">
              <label htmlFor="last_name">Last Name</label>
              <br />
              <input
                type="text"
                name="last_name"
                required
                value={last_name}
                onChange={(e) => setLast_name(e.target.value)}
                placeholder="Enter your last name"
              />
            </div>
          </div>
          <div className="signup_input">
            <div className="input_div">
              <label htmlFor="mail">Email</label>
              <input
                required
                type="email"
                placeholder="Enter your email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input_div">
              <label htmlFor="residence_country">Residence Country</label>
              <br />
              <PhoneInput
                className="phone_input"
                name="phone_number"
                country={"it"}
                placeholder="Enter phone number"
                value={phone}
                required
                onChange={(value) => {
                  setPhone(value);
                }}
              />
            </div>
          </div>
          <div className="">
            <div className="input_div radio_input">
              <div>
                <input
                  type="radio"
                  name="age"
                  value="Option 1"
                  checked={radio === "Option 1"}
                  onChange={(e) => setRadio(e.target.value)}
                />
                <label htmlFor="age1">Option 1</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="age"
                  value="Option 2"
                  checked={radio === "Option 2"}
                  onChange={(e) => setRadio(e.target.value)}
                />
                <label htmlFor="age2">Option 2</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="age"
                  value="Option 3"
                  checked={radio === "Option 3"}
                  onChange={(e) => setRadio(e.target.value)}
                />
                <label htmlFor="age3">Option 3</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="age"
                  value="Option 4"
                  checked={radio === "Option 4"}
                  onChange={(e) => setRadio(e.target.value)}
                />
                <label htmlFor="age4">Option 4</label>
              </div>
            </div>
          </div>
          <div className="input_div">
            <label htmlFor="discription">Discription</label>
            <textarea
              className="textareaField"
              value={details}
              placeholder="Enter your discription"
              onChange={(e) => setDetails(e.target.value)}
            ></textarea>
          </div>
          <button disabled={isLoading ? true : false} className="btn">
            {isLoading ? (
              <div className="loading_circle_btn"></div>
            ) : (
              "Send Message"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
