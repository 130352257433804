import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import BackIntro from "../../Components/Cards/BackIntro";
// import Video from "../../Components/Cards/Video";
import Vimeo from "@u-wave/react-vimeo";
import thepdf from "../../Assets/pdf.pdf";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";
import CourseApi from "../../api/services/CourseApi";

const LessonDetails = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [lessionContent, setLessionContent] = useState({});
  const [pageNo, setPageNo] = useState(1);
  // const [videoConfig, setVideoConfig] = useState<any>({});

  useEffect(() => {
    CourseApi.getLessonContents(id, pageNo, {
      showIndicator: true,
      onSuccess: (response) => setLessionContent(response.lessons),
    });
  }, [pageNo]);

  useEffect(() => {
    scrollToTop();
  }, []);

  console.log("lessionContent", lessionContent);
  return (
    <div className="course_contaner">
      <BackIntro title="Lesson list" />

      <div className="couser_main">
        <h2 className="c_title">{state.data.class_name}</h2>
        <div className="course_demo_info">
          {state.data.video_url && (
            <div className="video">
              <Vimeo video={state.data.video_url} style={{ width: "100%" }} />
            </div>
            //   <Video url={Urls.storagePath + state.data.video_url} />
          )}
        </div>

        {/* excercise */}
        {/* {state.data?.quizzes?.length > 0 && (
          <div className="day_box">
            <div className="amico_img">
              <img src={amico} alt="" />
            </div>
            <Link
              to={`/${course}/lessons/${state.data.id}/exercise`}
              state={{
                title: state.data.class_name,
                quizzes: state.data.quizzes,
              }}
              className="btn exc_btn"
            >
              Try Exceries
            </Link>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default LessonDetails;
