import React, { createContext, useState } from "react";
import { LogoutApiService } from "../api/ApiServices";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [click, setClick] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [user, setUser] = useState(null);
  // const [patenteLessons, setPatenteLessons] = useState({});
  // const [a2b1Lessons, setA2b1Lessons] = useState({});
  // const [cafLessons, setCafLessons] = useState({});

  const saveUserProfile = (userInfo) => {
    localStorage.setItem("user", JSON.stringify(userInfo));
    setUser(userInfo);
  };

  const saveAccessToken = (accessToken) => {
    localStorage.setItem("access_token", accessToken);
    window.accessToken = accessToken;
  };

  const signOut = () => {
    LogoutApiService(() => {
      window.accessToken = "";
      setUser(null);
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
    });
  };

  const info = {
    isLoading,
    setIsLoading,
    user,
    setUser,
    saveUserProfile,
    saveAccessToken,
    // patenteLessons,
    // setPatenteLessons,
    // a2b1Lessons,
    // setA2b1Lessons,
    // cafLessons,
    // setCafLessons,
    click,
    setClick,
    signOut,
  };

  return <AuthContext.Provider value={info}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
