import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackIntro from "../Components/Cards/BackIntro";
import scrollToTop from "../Components/ScrollTop/ScrollTop";
import CourseApi from "../api/services/CourseApi";
import { useSelector } from "react-redux";
import Urls from "../api/Urls";

const Quizzes = () => {
  const { sectionId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isLogedIn = useSelector((state) => state.appReducer.isLogedIn);
  const [isLoading, setIsLoading] = useState(false);
  const [nextPageNo, setNextPageNo] = useState(0);
  const [quizzes, setQuizzes] = useState([]);
  const [section, setSection] = useState({});
  const [showResult, setshowResult] = useState(false);

  useEffect(() => {
    if (sectionId) {
      fetchApiData(sectionId);
    } else if (state?.quizzes) {
      const newQuizzes = state.quizzes.reduce(
        (result, item) => [...result, { ...item, user_answer: undefined }],
        []
      );
      setQuizzes(newQuizzes);
    }

    scrollToTop();
  }, []);

  const fetchApiData = (sId) => {
    setIsLoading(true);
    CourseApi.getSectionQuizzes(sId, {
      onSuccess: (response) => {
        // console.log('getSectionQuizzes', response);
        const newQuizzes = response.data.quiz.reduce(
          (result, item) => [...result, { ...item, user_answer: undefined }],
          []
        );
        setQuizzes(newQuizzes);
        setSection({
          id: response.data.id,
          name: response.data.name,
          description: response.data.description,
          image: response.data.image,
        });
        setIsLoading(false);
      },
      onError: () => setIsLoading(false),
    });
  };

  const getInputStyle = (item) => {
    if (showResult) {
      if (item.user_answer?.toUpperCase() === item.answer?.toUpperCase()) {
        return "right";
      } else if (
        item.user_answer?.toUpperCase() !== item.answer?.toUpperCase()
      ) {
        return "worng";
      }
    }
    return "";
  };

  const getBtnBgColor = (userAnswer, answer, ignore) => {
    let bgColor = "";

    if (userAnswer != undefined && !ignore) {
      if (showResult) {
        bgColor = userAnswer == answer ? "green" : "red";
      } else {
        bgColor = "selected";
      }
    }

    return bgColor;
  };

  const getQuestionInput = (item, index) => {
    const splitQues = item?.question?.split("___");

    return (
      <li key={index}>
        <div className="exc_item">
          <div className="info">
            <span className="index">{index + 1}</span>
            <div className="question_area">
              <span>{splitQues[0]}</span>
              {showResult && (
                <div className="ans_area">
                  <span>
                    {item.answer_type === "true-false"
                      ? item?.answer == "1"
                        ? "Vero"
                        : "Falso"
                      : item.answer}
                  </span>
                </div>
              )}
            </div>
          </div>
          {item.answer_type === "true-false" ? (
            <div className="inputButton">
              <button
                className={getBtnBgColor(
                  item.user_answer,
                  item.answer,
                  item.user_answer === false
                )}
                onClick={() => {
                  const newQuizzes = [...quizzes];
                  newQuizzes[index].user_answer = true;
                  setQuizzes(newQuizzes);
                }}
              >
                Vero
              </button>
              <button
                className={getBtnBgColor(
                  item.user_answer,
                  item.answer,
                  item.user_answer === true
                )}
                onClick={() => {
                  const newQuizzes = [...quizzes];
                  newQuizzes[index].user_answer = false;
                  setQuizzes(newQuizzes);
                }}
              >
                Falso
              </button>
            </div>
          ) : (
            <input
              onChange={(e) => {
                const newQuizzes = [...quizzes];
                newQuizzes[index].user_answer = e.target.value;
                setQuizzes(newQuizzes);
              }}
              className={getInputStyle(item)}
              type="text"
              placeholder="Write your answer"
              value={quizzes[index].user_answer}
            />
          )}

          {splitQues[1] && <span>{splitQues[1]}</span>}
        </div>

        {/* answer show area */}
        {/* {showResult && (
          <div className="ans_area">
            <span>{item?.answer}</span>
          </div>
        )} */}
      </li>
    );
  };

  // console.log("quizzes", quizzes);
  return (
    <div className="Exc_container">
      <BackIntro title="Sections" />

      <div className="exc_main">
        <h2 className="c_title">{`Quiz exercise`}</h2>

        {section.image && (
          <div className="exc_image">
            <img src={Urls.storagePath + section.image} alt="" />
          </div>
        )}

        <div className="exe_box">
          <ul>{quizzes.map((item, index) => getQuestionInput(item, index))}</ul>
          <button
            onClick={() => setshowResult(!showResult)}
            className="btn exc_btn"
          >
            {showResult ? "Hide Result" : "See Result"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Quizzes;
