import React, { useRef, useEffect } from "react";
import upload from "../../Assets/upload.svg";
import { useDropzone } from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
  ChangeProfilePicApiService,
  GetProfileApiService,
} from "../../api/ApiServices";
import { AuthContext } from "../../Context/AuthProvider";

const DragDropProfile = ({ closeModal }) => {
  const { saveUserProfile } = React.useContext(AuthContext);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [cropData, setCropData] = React.useState(null);
  const cropperRef = useRef(null);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);
    // handleImage(file);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (selectedImage && cropperRef.current) {
      const initialCropData = { width: 400, height: 400 };
      cropperRef.current.cropper.setCropBoxData(initialCropData);
      cropperRef.current.cropper.setData(initialCropData);
    }
  }, [selectedImage]);

  const handleCrop = () => {
    if (!cropperRef.current) {
      return;
    }
    cropperRef.current.cropper.getCroppedCanvas().toBlob(
      (blob) => {
        const formData = new FormData();
        
        formData.append("profile_pic", blob);
        setIsLoading(true);
        ChangeProfilePicApiService(
          formData,
          () => {
            setIsLoading(false);
            closeModal();
            GetProfileApiService((response) => saveUserProfile(response));
          },
          () => setIsLoading(false)
        );
      }
    );
  };

  const cancelCrop = () => {
    setSelectedImage(null);
    setCropData(null);
  };

  return (
    <div>
      {!selectedImage && (
        <>
          <div
            {...getRootProps()}
            className={`upload_img profile_pic ${isDragActive ? "active" : ""}`}
          >
            <img src={upload} alt="Upload Icon" />
            <input {...getInputProps()} accept="image/*" />
            {isDragActive ? (
              <p>Drop the profile picture here</p>
            ) : (
              <p>
                Drag and drop a profile picture here, or click to select a file
              </p>
            )}
          </div>
          <button onClick={closeModal} className="btn">
            Cancel
          </button>
        </>
      )}

      {selectedImage && !cropData && (
        <div className="crop_button croping_img">
          <Cropper
            ref={cropperRef}
            src={selectedImage}
            style={{ height: 400, width: "100%" }}
            aspectRatio={1}
            guides={true}
          />
          <button
            disabled={isLoading ? true : false}
            onClick={handleCrop}
            className="btn"
          >
            {isLoading ? (
              <div className="loading_circle_btn"></div>
            ) : (
              "Crop and Save"
            )}
          </button>
          <button className="btn" onClick={cancelCrop}>
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default DragDropProfile;
