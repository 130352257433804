import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../Context/AuthProvider";

const PrivateRoute = ({ children }) => {
  const { loading } = useContext(AuthContext);
  const current_user = localStorage.getItem("user");
  const location = useLocation();

  if (loading) {
    return (
      <div className="centerLoading">
        {" "}
        <div className="loading_circle"></div>
      </div>
    );
  }

  if (current_user) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
};

export default PrivateRoute;
