import { toast } from "react-toastify";
import { toast_error, toast_success } from "../Toast/Toast";
import {
  getRequest,
  postRequest,
  deleteRequest,
  multipartPostRequest,
  authenticateGetRequest,
  authenticatePostRequest,
  authenticateDeleteRequest,
  multipartAuthenticatePostRequest,
} from "./ApiRequest";
import Urls from "./Urls";

const globalAny = global;

export const SUCCESS = "success";
export const ERROR = "error";
export const VALIDATIONERROR = "ValidationError";
const UNAUTHENTICATED = "unauthenticated";

const HandleApiResponse = (jsonResponse, onSuccess) => {
  const dataParse = JSON.parse(jsonResponse);
  // console.log("HandleApiResponse", dataParse);

  if (dataParse.status === SUCCESS) {
    onSuccess(dataParse);
  } else if (dataParse.status === ERROR) {
    toast(dataParse.msg, {
      type: "custom_error",
      duration: 10000,
    });
  }
};

const HandleAuthenticateApiResponse = (jsonResponse, onSuccess, onError) => {
  const dataParse = JSON.parse(jsonResponse);

  if (dataParse.status === UNAUTHENTICATED) {
  } else if (dataParse.status === SUCCESS) {
    onSuccess(dataParse);
  } else if (dataParse.status === ERROR) {
    toast.show(dataParse.msg, {
      type: "custom_error",
      duration: 10000,
    });
    if (onError) {
      onError(dataParse);
    }
  }
};

export const RegistrationApiService = async (
  body,
  onSuccess,
  failedCallback
) => {
  try {
    const jsonResponse = await postRequest(Urls.register, body);
    const dataParse = JSON.parse(jsonResponse);
    // console.log("RegistrationApiService", dataParse);
    if (dataParse.status === SUCCESS) {
      toast_success(
        "You have successfully registered. Please login to continue."
      );
      onSuccess(dataParse);
    } else if (dataParse?.message) {
      toast_error(dataParse.message);
      failedCallback(dataParse);
    }
  } catch (error) {
    throw error;
  }
};

export const ForgotPasswordApiService = async (body, onSuccess) => {
  try {
    const jsonResponse = await postRequest(Urls.forgotPassword, body);

    const dataParse = JSON.parse(jsonResponse);
    // console.log("ForgotPasswordApiService", dataParse);
    if (dataParse.status === SUCCESS) {
      toast.show(dataParse.message, {
        type: "custom_success",
      });
      onSuccess();
    } else if (dataParse?.message) {
      toast.show(dataParse.message, {
        type: "custom_error",
        duration: 10000,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const ResetPasswordApiService = async (body, onSuccess) => {
  try {
    const jsonResponse = await postRequest(Urls.resetPassword, body);

    const dataParse = JSON.parse(jsonResponse);
    // console.log('ResetPasswordApiService', dataParse);
    if (dataParse.status === SUCCESS) {
      toast.show(dataParse.message, {
        type: "custom_success",
      });
      onSuccess();
    } else if (dataParse?.message) {
      toast.show(dataParse.message, {
        type: "custom_error",
        duration: 10000,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const GetProfileApiService = async (onSuccess) => {
  try {
    const jsonResponse = await authenticateGetRequest(Urls.getProfile);
    const dataParse = JSON.parse(jsonResponse);
    // console.log("GetProfileApiService", dataParse);
    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.data);
    }
  } catch (error) {
    throw error;
  }
};

export const EditProfileApiService = async (
  body,
  onSuccess,
  failedCallback
) => {
  try {
    const jsonResponse = await authenticatePostRequest(
      Urls.updateProfile,
      body
    );

    const dataParse = JSON.parse(jsonResponse);
    if (dataParse.status === SUCCESS) {
      // saveUerCreadential(dataParse.data, globalAny.accessToken, true);
      toast_success(dataParse.message);
      onSuccess(dataParse);
    } else if (dataParse?.message) {
      toast_error(dataParse.message);
      failedCallback();
    }
  } catch (error) {
    throw error;
  }
};

export const ChangeProfilePicApiService = async (body, onSuccess, onError) => {
  try {
    const jsonResponse = await multipartAuthenticatePostRequest(
      Urls.updateProPic,
      body
    );
    const dataParse = JSON.parse(jsonResponse);
    // console.log("ChangeProfilePicApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      // Save user credentials
      toast_success(dataParse.message);
      onSuccess();
    } else if (dataParse?.message) {
      toast_error(dataParse?.message);
      onError();
    } else {
      onError();
    }
  } catch (error) {
    throw error;
  }
};

export const SendOtpApiService = async (body, onSuccess) => {
  try {
    const jsonResponse = await postRequest(Urls.generateOtp, body);
    // setTimeout(() => store.dispatch(hideSpinner()), 50);
    const dataParse = JSON.parse(jsonResponse);
    // console.log("SendOtpApiService", dataParse);
    onSuccess();
  } catch (error) {
    throw error;
  }
};

export const LoginApiService = async (body, onSuccess, failedCallback) => {
  try {
    const jsonResponse = await postRequest(Urls.login, body);
    const dataParse = JSON.parse(jsonResponse);
    // console.log("LoginApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    } else if (dataParse?.message) {
      failedCallback(dataParse);
    }
  } catch (error) {
    throw error;
  }
};

export const LogoutApiService = async (onSuccess, failedCallback) => {
  try {
    const jsonResponse = await authenticatePostRequest(Urls.logout, {});
    const dataParse = JSON.parse(jsonResponse);
    onSuccess(dataParse);
  } catch (error) {
    throw error;
  }
};

export const GetUserAllServiceApiService = async (onSuccess) => {
  try {
    const jsonResponse = await authenticateGetRequest(Urls.userAllServices);

    const dataParse = JSON.parse(jsonResponse);
    // console.log("GetUserAllServiceApiService", dataParse);
    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    }
  } catch (error) {
    throw error;
  }
};

export const GetCafServiceApiService = async (isLogedIn, onSuccess) => {
  try {
    let jsonResponse;
    if (isLogedIn) {
      jsonResponse = await authenticateGetRequest(Urls.userCafServices);
      HandleAuthenticateApiResponse(jsonResponse, onSuccess);
    } else {
      jsonResponse = await getRequest(Urls.cafServices);
      const dataParse = JSON.parse(jsonResponse);
      onSuccess(dataParse);
    }
    const dataParse = JSON.parse(jsonResponse);
    // console.log(dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    }
  } catch (error) {
    throw error;
  }
};

export const RequestOtherServiceApiService = async (body, onSuccess) => {
  try {
    const jsonResponse = await authenticatePostRequest(
      Urls.requestOtherService,
      body
    );

    const dataParse = JSON.parse(jsonResponse);
    // console.log('RequestOtherServiceApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      toast.show(dataParse?.message, {
        type: "custom_error",
        duration: 10000,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const GetCountriesServiceApiService = async (onSuccess) => {
  try {
    const jsonResponse = await getRequest(Urls.visaCountries);
    // setTimeout(() => store.dispatch(hideSpinner()), 50);

    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetCountriesServiceApiService', dataParse);
    if (dataParse.status === SUCCESS) {
      // const countries = dataParse.countries.reduce(
      //   (allCountries: any[], item: any) => [...allCountries, item.iso],
      //   [],
      // );
      // console.log('countries', countries);
      onSuccess(dataParse.countries);
    }
  } catch (error) {
    throw error;
  }
};

export const GetVisaServiceApiService = async (
  visaType,
  countryCode,
  onSuccess,
  onError
) => {
  try {
    const jsonResponse = await getRequest(
      `${Urls.visaServices}/${visaType}/${countryCode}`
    );
    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetVisaServiceApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    } else if (dataParse?.message) {
      toast.show(dataParse.message, {
        type: "custom_error",
        duration: 10000,
      });
      if (onError) {
        onError();
      }
    } else {
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    throw error;
  }
};

export const GetUserVisaApiService = async (onSuccess) => {
  try {
    const jsonResponse = await authenticateGetRequest(
      `${Urls.userVisaServices}`
    );
    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetUserVisaApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    } else if (dataParse?.message) {
      toast.show(dataParse.message, {
        type: "custom_error",
        duration: 10000,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const RequestServiceApiService = async (
  body,
  onSuccess,
  failedCallback
) => {
  try {
    const jsonResponse = await multipartAuthenticatePostRequest(
      Urls.requestService,
      body
    );

    const dataParse = JSON.parse(jsonResponse);
    // console.log('RequestServiceApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      failedCallback(dataParse.message);
    }
  } catch (error) {
    throw error;
  }
};

export const RequestVisaServiceApiService = async (body, onSuccess) => {
  try {
    const jsonResponse = await authenticatePostRequest(
      Urls.requestVisaService,
      body
    );

    const dataParse = JSON.parse(jsonResponse);
    // console.log('RequestVisaServiceApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      toast.show(dataParse?.message, {
        type: "custom_error",
        duration: 10000,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const GetTicketServiceApiService = async (onSuccess) => {
  try {
    const jsonResponse = await authenticateGetRequest(Urls.userTicketServices);

    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetTicketServiceApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.tickets);
    } else if (dataParse?.message) {
      toast.show(dataParse.message, {
        type: "custom_error",
        duration: 10000,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const RequestTicketServiceApiService = async (body, onSuccess) => {
  try {
    const jsonResponse = await authenticatePostRequest(
      Urls.requestTicketServices,
      body
    );

    const dataParse = JSON.parse(jsonResponse);
    // console.log('RequestTicketServiceApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      toast.show(dataParse?.message, {
        type: "custom_error",
        duration: 10000,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const GetTourServiceApiService = async (onSuccess) => {
  try {
    let jsonResponse;
    // store get started
    // if (store.getState().appReducer.isLogedIn) {
    //   jsonResponse = await authenticateGetRequest(Urls.userTourServices);
    //
    //   // HandleAuthenticateApiResponse(jsonResponse, onSuccess)
    // } else {
    //   jsonResponse = await getRequest(Urls.tourServices);
    //
    // }

    const dataParse = JSON.parse(jsonResponse);
    // console.log("GetTourServiceApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    } else if (dataParse?.message) {
      toast.show(dataParse.message, {
        type: "custom_error",
        duration: 10000,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const RequestTourApiService = async (body, onSuccess) => {
  try {
    const jsonResponse = await authenticatePostRequest(Urls.requestTour, body);

    const dataParse = JSON.parse(jsonResponse);
    // console.log('RequestTourApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      toast.show(dataParse?.message, {
        type: "custom_error",
        duration: 10000,
      });
    }
  } catch (error) {
    throw error;
  }
};

//course and quize
export const GetCourseLeasonApiService = async (type, onSuccess, onError) => {
  try {
    const jsonResponse = await getRequest(
      `${Urls.getCourseLeason}/${type}/lessons`
    );
    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetCourseLeasonApiService', dataParse);
    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.course_details);
    } else {
      onError();
    }
  } catch (error) {
    throw error;
  }
};

export const RequestCourseApiService = async (body, onSuccess) => {
  try {
    const jsonResponse = await authenticatePostRequest(
      Urls.requestCourse,
      body
    );

    const dataParse = JSON.parse(jsonResponse);
    // console.log('RequestCourseApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      toast.show(dataParse?.message, {
        type: "custom_error",
        duration: 10000,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const GetUserCourseApiService = async (onSuccess, onError) => {
  try {
    const jsonResponse = await authenticateGetRequest(Urls.getUserCourses);
    const dataParse = JSON.parse(jsonResponse);
    // console.log("GetUserCourseApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.user_courses);
    } else {
      onError();
    }
  } catch (error) {
    throw error;
  }
};

export const GetSimulationQuizApiService = async (onSuccess) => {
  try {
    const jsonResponse = await authenticateGetRequest(Urls.simulationQuiz);

    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetSimulationQuizApiService', dataParse);
    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.quizzes);
    }
  } catch (error) {
    throw error;
  }
};

export const GetCapitoliQuizApiService = async (params, onSuccess) => {
  try {
    const jsonResponse = await authenticatePostRequest(
      Urls.capitoliQuiz,
      params
    );

    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetCapitoliQuizApiService', dataParse);
    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.quizzes);
    }
  } catch (error) {
    throw error;
  }
};

export const GetCurrencyRateApiService = async (onSuccess) => {
  try {
    const jsonResponse = await getRequest(Urls.currencyRate);
    // setTimeout(() => store.dispatch(hideSpinner()), 50);
    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetCurrencyRateApiService', dataParse);
    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    }
  } catch (error) {
    throw error;
  }
};

export const GetLinksApiService = async (onSuccess) => {
  try {
    const jsonResponse = await getRequest(Urls.links);
    // setTimeout(() => store.dispatch(hideSpinner()), 50);
    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetLinksApiService', dataParse);
    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.links);
    }
  } catch (error) {
    throw error;
  }
};

// export const DownloadPdfApiService = (
//   fileName,
//   url,
//   downloadMsg
// ) => {
//

//   const { dirs } = RNFetchBlob.fs;
//   const dirToSave = Platform.OS === 'ios' ? dirs.DocumentDir : dirs.DownloadDir;
//   const downloadURL = `${Urls.storagePath}${url}`;
//   // console.log('downloadURL', downloadURL);

//   RNFetchBlob.config({
//     addAndroidDownloads: {
//       useDownloadManager: true,
//       notification: true,
//       mediaScannable: true,
//       title: `${fileName}.pdf`,
//       path: `${dirToSave}/${fileName}${new Date().getMilliseconds()}.pdf`,
//     },
//     fileCache: true,
//     path: `${dirToSave}/${fileName}.pdf`,
//     appendExt: 'pdf',
//     IOSBackgroundTask: true,
//     indicator: true,
//   })
//     .fetch('GET', downloadURL, {
//       Authorization: 'Bearer ' + globalAny.accessToken,
//     })
//     .then(res => {
//

//       if (Platform.OS === 'ios') {
//         RNFetchBlob.ios.openDocument(res.path());
//       } else {
//         toast.show(downloadMsg, {
//           type: 'custom_success',
//         });
//       }
//       // console.log('The file saved to ', res);
//     })
//     .catch(errorMessage => {
//       // error handling
//       // console.log('errorMessage- ', errorMessage);
//
//     });
// };

export const GetCarServiceApiService = async (onSuccess) => {
  try {
    const jsonResponse = await authenticateGetRequest(Urls.userCarServices);

    const dataParse = JSON.parse(jsonResponse);
    // console.log("GetCarServiceApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.car_services);
    }
  } catch (error) {
    throw error;
  }
};

export const RequestInsuranceServiceApiService = async (
  body,
  onSuccess,
  onFailed
) => {
  try {
    const jsonResponse = await authenticatePostRequest(
      Urls.requestInsurance,
      body
    );

    const dataParse = JSON.parse(jsonResponse);
    // console.log("RequestInsuranceServiceApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      onFailed(dataParse?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const RequestCarServiceApiService = async (
  body,
  onSuccess,
  onFailed
) => {
  try {
    const jsonResponse = await authenticatePostRequest(Urls.requestCar, body);

    const dataParse = JSON.parse(jsonResponse);
    // console.log("RequestCarServiceApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      onFailed(dataParse?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const GetAdsApiService = async (onSuccess) => {
  try {
    const jsonResponse = await getRequest(Urls.ad);

    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetAdsApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.ad);
    }
  } catch (error) {
    throw error;
  }
};

export const GetUserAdsApiService = async (onSuccess) => {
  try {
    const jsonResponse = await authenticateGetRequest(Urls.userAd);
    const dataParse = JSON.parse(jsonResponse);
    console.log("GetUserAdApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    }
  } catch (error) {
    throw error;
  }
};

export const RequestAdvertiseApiService = async (body, onSuccess) => {
  try {
    const jsonResponse = await multipartAuthenticatePostRequest(
      Urls.requestAd,
      body
    );

    const dataParse = JSON.parse(jsonResponse);
    // console.log("RequestAdvertiseApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      toast_error(dataParse?.message);
    }
  } catch (error) {
    throw error;
  }
};

//blogs
export const GetBlogsApiService = async (url, onSuccess, onError) => {
  try {
    const jsonResponse = await getRequest(url);
    const dataParse = JSON.parse(jsonResponse);
    console.log("GetBlogsApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    } else if (dataParse?.message) {
      onError();
      toast_error(dataParse?.message);
    } else {
      onError();
    }
  } catch (error) {
    onError();
    throw error;
  }
};

export const GetBlogDetailsApiService = async (id, onSuccess) => {
  try {
    let jsonResponse = await getRequest(Urls.getBlogDetails + id);
    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetBlogDetailsApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.blog);
    } else if (dataParse?.message) {
      toast_error(dataParse?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const RequestBenefitApiService = async (body, onSuccess) => {
  try {
    const jsonResponse = await multipartAuthenticatePostRequest(
      Urls.requestBenefit,
      body
    );

    console.log("jsonResponse", jsonResponse);
    const dataParse = JSON.parse(jsonResponse);
    // console.log('RequestBenefitApiService', dataParse);

    if (dataParse.status == SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      toast_error(dataParse?.message);
    }
  } catch (error) {
    throw error;
  }
};
